import { hasDuplicateSampleNumbers } from '@resistapp/common/environments';
import { FullProject, type Environment, type SampleStatus } from '@resistapp/common/types';
import { flattenSamplesByUID, getSampleSortValue, sortUniqEnvironments } from '@resistapp/common/utils';
import { chain } from 'lodash';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getEnvironmentDatas } from '../components/plots/bar-box-map/utils';
import { ResearchPlotData, buildResearchPlotData } from '../data-utils/plot-data/research-plot-data';
import { QueryFilters } from '../hooks/use-query-filters/use-query-filters';
import { useResearchAdmin } from '../hooks/use-research-admin';
import { useAssayContext } from './assay-context';
import { useSampleDataContext } from './sample-data-context';

export interface SampleSelectionState {
  ids: string[];
  removeOldSelections: boolean;
}

interface BaseResearch {
  error: Error | null;
  loading: boolean;
  queryFilters: QueryFilters;
  plotData: ResearchPlotData | null;
  project: FullProject | null;
  setGraphAndLegendContainerWidth: (width: number) => void;
  graphAndLegendContainerWidth: number;
  samplesBeingSelected: SampleSelectionState;
  setSamplesBeingSelected: (value: SampleSelectionState) => void;
  sampleStatusById: Record<string, SampleStatus>;
  samplesByEnvId: Record<number, Array<FullProject['samplesByUID'][string][number]>>;
  environments: Environment[];
  environmentIds: string[];
  nameById: Record<string, string>;
  environmentNames: string[];
  focusedSamplesByEnvId: Record<number, Array<FullProject['samplesByUID'][string][number]>>;
  adminFeatures: ReturnType<typeof useResearchAdmin>;
  sequentialSampleIndexes: Record<number, number>;
  areSampleNumbersUnique: boolean;
}

interface ResearchLoading extends BaseResearch {
  loading: true;
  plotData: null;
  project: null;
  againstTime: false;
}

interface ResearchLoaded extends BaseResearch {
  loading: false;
  plotData: ResearchPlotData | null;
  project: FullProject;
  againstTime: boolean;
}

const ResearchContext = createContext<ResearchLoading | ResearchLoaded | undefined>(undefined);

export function ResearchProvider({ children }: { children: React.ReactNode }) {
  const { data: project, queryFilters, loading, error } = useSampleDataContext();
  const { allGeneGroups, getGroup, assaysLoaded } = useAssayContext();
  const adminFeatures = useResearchAdmin();

  const [graphAndLegendContainerWidth, setGraphAndLegendContainerWidth] = useState(0);
  const [samplesBeingSelected, setSamplesBeingSelectedLocal] = useState<SampleSelectionState>({
    ids: [],
    removeOldSelections: false,
  });
  const sampleRef = useRef<SampleSelectionState>({
    ids: [],
    removeOldSelections: false,
  });
  sampleRef.current = samplesBeingSelected;

  const projectIsValid = project && validateProject(project);

  const allProjectEnvironmentNames = useMemo(() => {
    const projectEnvironments = project?.samplesByUID ? sortUniqEnvironments(project.samplesByUID, project.id) : [];
    const projectEnvironmentNames = projectEnvironments.map(env => env.name);
    return projectEnvironmentNames;
  }, [project?.samplesByUID, project?.id]);

  const selectedEnvironments = queryFilters.filters.selectedEnvironmentNamesOrdered;
  const toggleEnvironmentStable = queryFilters.toggleEnvironmentStable;
  // Clean up non-existent environments from URL
  useEffect(() => {
    if (!allProjectEnvironmentNames.length || !selectedEnvironments.length) {
      return;
    }

    // Always clean up if there are any selected environments to ensure they exist
    const validEnvironments = selectedEnvironments.filter(name => allProjectEnvironmentNames.includes(name));
    // Only update if we found invalid environments
    if (validEnvironments.length !== selectedEnvironments.length) {
      toggleEnvironmentStable(undefined, true);
    }
  }, [selectedEnvironments, toggleEnvironmentStable, allProjectEnvironmentNames]);

  const areSampleNumbersUnique = useMemo(() => {
    return project ? hasDuplicateSampleNumbers(project.samplesByUID) : true;
  }, [project]);

  // PREPARE RESEARCH PLOT DATA
  const plotData = useMemo(() => {
    // TODO this is heavy and should be run only once on load: when filters change.
    // This would be to avoid double runing it first on project load, and then immediatelly again
    // because filters.selectedEnvironmentNamesOrdered depends on the project.
    return projectIsValid && assaysLoaded
      ? buildResearchPlotData(project, queryFilters.filters, allGeneGroups, getGroup)
      : null;
  }, [projectIsValid, assaysLoaded, project, queryFilters.filters, allGeneGroups, getGroup]);

  const setSamplesBeingSelected = (newSelection: SampleSelectionState) => {
    if (newSelection.ids.length === 0) {
      setSamplesBeingSelectedLocal({ ids: [], removeOldSelections: newSelection.removeOldSelections });
    } else {
      setSamplesBeingSelectedLocal(newSelection);
    }
  };

  const { environments, environmentIds, nameById, environmentNames } = useMemo(
    () =>
      getEnvironmentDatas(project?.samplesByUID || {}, queryFilters.filters.selectedEnvironmentTypeGroup, project?.id),
    [project?.samplesByUID, queryFilters.filters.selectedEnvironmentTypeGroup, project?.id],
  );
  const sampleStatusById = useMemo(
    () =>
      chain(environments)
        .keyBy(env => `${env.id}`)
        .mapValues(env => env.sampleStatus)
        .value(),
    [environments],
  );

  const samplesByEnvId = useMemo(
    () =>
      chain(project?.samplesByUID || {})
        .values()
        .flatten()
        .groupBy(sample => sample.environment.id)
        .mapValues(samples => samples)
        .value(),
    [project?.samplesByUID],
  );

  const focusedSamplesByEnvId = useMemo(
    () =>
      chain(project?.focusedByUID || project?.samplesByUID || {})
        .values()
        .flatten()
        .groupBy(sample => sample.environment.id)
        .mapValues(samples => samples)
        .value(),
    [project?.focusedByUID, project?.samplesByUID],
  );

  const sequentialSampleIndexes = useMemo(() => {
    if (!project?.samplesByUID) return {};

    const allSamples = flattenSamplesByUID(project.samplesByUID);
    const sortedSamples = [...allSamples].sort(
      (a, b) => getSampleSortValue(a, project.id) - getSampleSortValue(b, project.id),
    );

    const indexMap: Record<number, number> = {};
    sortedSamples.forEach((sample, index) => {
      indexMap[sample.id] = index + 1;
    });

    return indexMap;
  }, [project?.samplesByUID, project?.id]);

  if (projectIsValid && !loading && assaysLoaded) {
    const contextData = {
      loading: false as const,
      plotData,
      project,
      queryFilters,
      error,
      setGraphAndLegendContainerWidth,
      graphAndLegendContainerWidth,
      samplesBeingSelected,
      setSamplesBeingSelected,
      againstTime: plotData?.againstTime || false,
      sampleStatusById,
      samplesByEnvId,
      focusedSamplesByEnvId,
      environments,
      environmentIds,
      nameById,
      environmentNames,
      adminFeatures,
      sequentialSampleIndexes,
      areSampleNumbersUnique,
    };
    return <ResearchContext.Provider value={contextData}>{children}</ResearchContext.Provider>;
  } else {
    const contextData = {
      loading: true as const,
      plotData: null,
      project: null,
      queryFilters,
      error,
      setGraphAndLegendContainerWidth,
      graphAndLegendContainerWidth,
      samplesBeingSelected,
      setSamplesBeingSelected,
      againstTime: false as const,
      sampleStatusById,
      samplesByEnvId,
      focusedSamplesByEnvId,
      environments,
      environmentIds,
      nameById,
      environmentNames,
      adminFeatures,
      sequentialSampleIndexes: {},
      areSampleNumbersUnique: true,
    };
    return <ResearchContext.Provider value={contextData}>{children}</ResearchContext.Provider>;
  }
}

export function useResearchContext() {
  const context = useContext(ResearchContext);

  if (!context) {
    throw new Error('useResearchContext must be used within a ResearchProvider');
  }

  return context;
}

/**
 * Validates the structure and content of the sampling data.
 * @param {FullProject | null} project - The sampling data to validate.
 * @returns {boolean} - Returns true if the sampling data is valid, otherwise false.
 */
function validateProject(project: Partial<FullProject>): project is FullProject {
  // Example validation: Ensure that the sampling has necessary properties
  const requiredProperties = ['samplesByUID', 'focusedByUID', 'qpcrFiles'];
  const hasAllProperties = requiredProperties.every(prop => prop in project);

  if (!hasAllProperties) {
    return false;
  }

  // Further validations can be added here based on project requirements
  return true;
}
