import styled from '@emotion/styled';
import { PlotTooltip, usePlotTooltip } from '@resistapp/client/components/tooltips/plot-tooltip';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { useSampleDataContext } from '@resistapp/client/contexts/sample-data-context';
import { BarDatum, BoxDatum } from '@resistapp/client/data-utils/plot-data/research-plot-data';
import { useExperimentalMetric } from '@resistapp/client/hooks/use-experimental-metric';
import { getKeyPressesFromMouseClick } from '@resistapp/client/utils/general';
import { GeneGrouping, L2Target } from '@resistapp/common/assays';
import { FullProject } from '@resistapp/common/types';
import { AxisScale } from '@visx/axis';
import { Glyph } from '@visx/glyph';
import { Group } from '@visx/group';
import { BoxPlot } from '@visx/stats';
import { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { ScaleLinear, ScaleOrdinal } from 'd3-scale';
import { Dictionary } from 'lodash';
import React, { useCallback } from 'react';
import { getTextColor } from '../../shared/palettes';
import { noTextSelection, theme } from '../../shared/theme';
import type { KeysPressOptions } from '../legends/legend';
import { GenesBarStack } from './genes-bar-stack';
import { ScaleBand, ScaleTime } from './multi-plot';
import { BoxTooltipContent } from './tooltips';
import { getEnvironmentDatas, getSampleSelector, getSelectedEnvironmentIds, getSemiTransparentColor } from './utils';

export interface GroupsProps extends GroupProps {
  data: Array<BoxDatum[] | BarDatum[]>;
  colorScale: ScaleOrdinal<string, string> | undefined;
  barPlotGeneGroups: string[] | undefined;
  sortIdxByGene: Dictionary<number>;
  type: 'bar' | 'box' | 'heat';
  project: FullProject;
}

export function DataGroups(props: GroupsProps) {
  const { barPlotGeneGroups, colorScale } = props;
  const groupLength = Math.max(...props.data.map(datums => datums.length));

  if (barPlotGeneGroups && colorScale) {
    // Detected genes bar groups
    // OR heatmap bar groups (if no color detectedGenesColorScale provided)
    const data = props.data as BarDatum[][];

    return data.map((datums, i) => (
      <BarGroup
        i={i}
        key={`bargroup${i}`}
        datums={datums}
        {...props}
        isHeatmap={props.type === 'heat'}
        colorScale={colorScale}
        groupLength={groupLength}
        geneGroups={barPlotGeneGroups}
        totalGroupSize={data.length}
      />
    ));
  } else {
    // Box groups
    const data = props.data as BoxDatum[][];
    return data.map((datums, i) => (
      <BoxGroup i={i} key={`boxgroup${i}`} groupLength={groupLength} datums={datums} {...props} />
    ));
  }
}

interface GroupProps {
  xScale: AxisScale<number>;
  yScale: ScaleLinear<number, number>;
  againstTime: boolean;
  groupWidth: number;
  ordinalAligmentFixup: number;
  tooltipStuff: UseTooltipInPortal;
  grouping: GeneGrouping;
  sortIdxByGene: Dictionary<number>;
  singleGeneHeatBoxHeight: number;
  showSampleNumbers: boolean;
  project: FullProject;
}

interface BarGroupPropsWoData extends GroupProps {
  i: number;
  geneGroups: string[];
  colorScale: ScaleOrdinal<string, string>;
  isHeatmap: boolean;
  groupLength: number;
  project: FullProject;
}

export interface GeneBarStackProps extends BarGroupPropsWoData {
  datum: BarDatum;
  currentGroupLength: number;
  j: number;
  totalGroupSize: number;
  bioRepCount: number;
}

interface BarGroupProps extends BarGroupPropsWoData {
  datums: BarDatum[];
  totalGroupSize: number;
}

function BarGroup(props: BarGroupProps) {
  // Number of detected barstack group
  // OR heatmap barstack group (if detectedGenesColorScale not provided)
  const { i, datums } = props;
  const stackProps = { ...props };
  delete (stackProps as Partial<BarGroupProps>).datums;

  return (
    <Group>
      {datums.map((barDatum, j) => {
        return (
          <GenesBarStack
            key={`stackedbargroup-${i}-${j}`}
            {...stackProps}
            j={j}
            datum={barDatum}
            currentGroupLength={datums.length}
            totalGroupSize={props.totalGroupSize}
            bioRepCount={datums.length}
          />
        );
      })}
    </Group>
  );
}

interface BoxGroupProps extends GroupProps {
  i: number;
  datums: BoxDatum[];
  groupLength: number;
}

function BoxGroup(props: BoxGroupProps) {
  const { i, ordinalAligmentFixup, againstTime, datums, xScale, groupWidth: width, groupLength } = props;

  const scaledX = againstTime ? (xScale as ScaleTime)(new Date(datums[0].date)) : (xScale as ScaleBand)(`${i}`);
  const left = scaledX - 0.5 * width + ordinalAligmentFixup;
  const { groupLeftMargin, itemWidth } = getGroupItemWidthAndMargin(width, groupLength, datums.length);

  return (
    <Group left={left}>
      {datums.map((datum, j) => (
        <Box key={`box${j}`} j={j} {...props} groupLeftMargin={groupLeftMargin} itemWidth={itemWidth} datum={datum} />
      ))}
    </Group>
  );
}

interface BoxProps extends BoxGroupProps {
  datum: BoxDatum;
  itemWidth: number;
  groupLeftMargin: number;
  j: number;
}

function Box(props: BoxProps) {
  const { datum, yScale, j, groupLeftMargin, itemWidth, tooltipStuff, showSampleNumbers, project } = props;
  const { queryFilters } = useSampleDataContext();
  const { samplesBeingSelected, setSamplesBeingSelected } = useResearchContext();
  const { tooltipData, tooltipProps, handleMouseMove } = usePlotTooltip<BoxDatum>(tooltipStuff);
  const experimentalMetric = useExperimentalMetric();
  const exprimentalMetricValue = experimentalMetric
    ? datum.experimentalMetrics?.[experimentalMetric]?.toFixed(experimentalMetric.startsWith('A') ? 1 : 0)
    : null;

  const { environmentNames, nameById } = getEnvironmentDatas(
    project.samplesByUID,
    queryFilters.filters.selectedEnvironmentTypeGroup,
  );
  const onClick = useCallback(
    (label: string, keyPresses: KeysPressOptions) =>
      getSampleSelector(
        showSampleNumbers,
        queryFilters.filters.selectedEnvironmentNamesOrdered as L2Target[],
        environmentNames as L2Target[],
        samplesBeingSelected,
        setSamplesBeingSelected,
        queryFilters.toggleEnvironmentStable,
        getSelectedEnvironmentIds(queryFilters.filters.selectedEnvironmentNamesOrdered, nameById),
        true,
        false,
        true,
      )(label, keyPresses),
    [
      showSampleNumbers,
      queryFilters.filters.selectedEnvironmentNamesOrdered,
      environmentNames,
      samplesBeingSelected,
      setSamplesBeingSelected,
      queryFilters.toggleEnvironmentStable,
      nameById,
    ],
  );

  const triggerProps = {
    onMouseOver: (event: React.MouseEvent<any>) => {
      handleMouseMove(event, datum, true);
    },
    onMouseLeave: (event: React.MouseEvent<any>) => {
      handleMouseMove(event, undefined);
    },
    onMouseMove: (event: React.MouseEvent<any>) => {
      handleMouseMove(event, datum);
    },
  };

  // If there is no proper data for a box, we still return an empty box with a tooltip mentioning there are no genes
  if (
    datum.min === undefined ||
    datum.max === undefined ||
    datum.firstQuartile === undefined ||
    datum.thirdQuartile === undefined ||
    datum.mean === undefined ||
    datum.outliers === undefined
  ) {
    return (
      <Group
        onClick={(e: React.MouseEvent) => {
          onClick(datum.label, getKeyPressesFromMouseClick(e));
        }}
        style={{ cursor: 'pointer' }}
      >
        <BoxPlot
          left={groupLeftMargin + j * itemWidth}
          boxWidth={itemWidth}
          fillOpacity={1}
          fill="transparent"
          stroke="transparent"
          strokeWidth={1}
          valueScale={yScale}
          container={true}
          containerProps={triggerProps}
          boxProps={triggerProps}
          minProps={triggerProps}
          maxProps={triggerProps}
          outlierProps={{ ...triggerProps, style: { opacity: 0.2 } }}
        />
        {tooltipData && (
          <PlotTooltip {...tooltipProps}>
            <div>
              <span style={{ fontWeight: theme.fontWeight.heavy }}>{datum.label}</span>: No genes detected
            </div>
          </PlotTooltip>
        )}
      </Group>
    );
  }
  const medianColor =
    datum.firstQuartile === datum.thirdQuartile // No box
      ? theme.colors.neutral900
      : getTextColor(datum.color, theme.colors.neutral300, theme.colors.neutral900);

  return (
    <Group
      onClick={(e: React.MouseEvent) => {
        onClick(datum.label, getKeyPressesFromMouseClick(e));
      }}
    >
      <BoxPlot
        left={groupLeftMargin + j * itemWidth}
        min={datum.min}
        max={datum.max}
        firstQuartile={datum.firstQuartile}
        thirdQuartile={datum.thirdQuartile}
        outliers={datum.outliers}
        median={datum.median}
        boxWidth={itemWidth}
        fillOpacity={1}
        fill={samplesBeingSelected.ids.includes(datum.label) ? getSemiTransparentColor(datum.color) : datum.color}
        stroke={samplesBeingSelected.ids.includes(datum.label) ? getSemiTransparentColor(datum.color) : datum.color}
        strokeWidth={1}
        medianProps={{ stroke: medianColor }}
        valueScale={yScale}
        container={true}
        containerProps={{
          ...triggerProps,
          style: { cursor: 'pointer' },
        }}
        boxProps={triggerProps}
        minProps={triggerProps}
        maxProps={triggerProps}
        outlierProps={{ ...triggerProps, style: { opacity: 0.2 } }}
      />
      <Croshair
        {...triggerProps}
        color={medianColor}
        left={groupLeftMargin + j * itemWidth + itemWidth / 2}
        top={props.yScale(datum.mean)}
      />
      {tooltipData && (
        <PlotTooltip {...tooltipProps}>
          <BoxTooltipContent tooltipData={tooltipData} />
        </PlotTooltip>
      )}
      {exprimentalMetricValue ? (
        <ExperimentalMetric
          top={265}
          left={groupLeftMargin + j * itemWidth + itemWidth / 2}
          value={exprimentalMetricValue}
          groupWidth={props.groupWidth}
        />
      ) : null}
    </Group>
  );
}

interface CroshairProps {
  left: number;
  top: number;
  color: string;
  onMouseOver: (event: React.MouseEvent<any>) => void;
  onMouseLeave: (event: React.MouseEvent<any>) => void;
  onMouseMove: (event: React.MouseEvent<any>) => void;
}

function Croshair({ onMouseOver, onMouseLeave, onMouseMove, left, top, color }: CroshairProps) {
  return (
    <X left={left} top={top}>
      <text
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        fontSize={8}
        fill={color}
        textAnchor="middle"
        dy="0.3em"
      >
        {'✕'}
      </text>
    </X>
  );
}

export function ExperimentalMetric({
  value,
  left,
  top,
  groupWidth,
}: {
  value: string;
  left: number;
  top: number;
  groupWidth: number;
}) {
  return (
    <X left={left} top={top}>
      <text
        fontSize={groupWidth < 30 ? 10 : 14}
        fill={'black'}
        textAnchor="top"
        dy={groupWidth < 30 ? '12.3em' : '10.3em'}
      >
        {value}
      </text>
    </X>
  );
}

const X = styled(Glyph)`
  ${noTextSelection}
  cursor: pointer;
`;

export function getGroupItemWidthAndMargin(groupWidth: number, maxGroupLength: number, currentGroupLength: number) {
  // Group width has 0.1 margin on both sides, leaving 0.8 for bars/boxes
  const itemWidth = (0.8 * groupWidth) / maxGroupLength;
  const slack = ((maxGroupLength - currentGroupLength) * itemWidth) / 2; // align shorter groups in the middle of their group slot
  const groupLeftMargin = 0.1 * groupWidth + slack;
  return {
    itemWidth,
    groupLeftMargin,
  };
}
