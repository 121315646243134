import { Kbd, ListItem, UnorderedList } from '@chakra-ui/react';
import { getEnvColorById } from '@resistapp/client/components/shared/palettes';
import { useResearchContext } from '@resistapp/client/contexts/research-context';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { useNormalisationMode } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { L2Target } from '@resistapp/common/assays';
import { FullProject, NormalisationMode } from '@resistapp/common/types';
import { getSampleSortValue } from '@resistapp/common/utils';
import { scaleOrdinal } from '@visx/scale';
import { isWithinInterval } from 'date-fns';
import { chain, findKey } from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { ChartHotkeyInstructions } from '../../tooltips/chart-hotkey-instructions';
import { getSampleSelector, getSelectedEnvironmentIds } from '../bar-box-map/utils';
import { BaseLegend } from './base-legend';
import { KeysPressOptions, Legend } from './legend';

interface SampleLegendProps {
  toggleEnvironment: (envName: string | string[], only: boolean) => void;
  filters: Filters;
  project: FullProject;
  legendHeight: string;
  id?: string;
  showSampleNumbers?: boolean;
}

export function SampleLegend(props: SampleLegendProps) {
  const { project, filters, toggleEnvironment, showSampleNumbers = false } = props;
  const {
    samplesBeingSelected,
    setSamplesBeingSelected,
    focusedSamplesByEnvId,
    environments,
    environmentIds,
    nameById,
    environmentNames,
    adminFeatures,
    sequentialSampleIndexes,
    areSampleNumbersUnique,
    queryFilters,
  } = useResearchContext();
  const normalisationMode = useNormalisationMode();
  const { isAdmin } = useUser();

  const activeKeys = useRef<KeysPressOptions | undefined>();
  // Create a mapping of environment ID to sample for sorting
  const envSampleMap = useMemo(() => {
    return chain(environments)
      .keyBy(env => env.id)
      .mapValues(env => {
        const sampleUID = findKey(project.samplesByUID, samples => samples[0].environment.id === env.id);

        if (!sampleUID) {
          return null;
        }

        const sample = project.samplesByUID[sampleUID][0];
        const rawSortValue = getSampleSortValue(sample, project.id);
        const sortIndex = sequentialSampleIndexes[sample.id] || rawSortValue;

        return { sample, sortIndex };
      })
      .pickBy() // Remove null values
      .value();
  }, [environments, project.samplesByUID, project.id, sequentialSampleIndexes]);

  // Sort the environment IDs by their samples' sort indexes
  const sortedEnvironmentIds = useMemo(() => {
    // Convert environmentIds to an array of objects with id and sortIndex
    const envWithIndex = environmentIds.map(Number).map(id => ({
      id: String(id),
      sortIndex: envSampleMap[Number(id)]?.sortIndex || 999999,
    }));

    // Sort by sortIndex and extract just the ids
    return envWithIndex.sort((a, b) => a.sortIndex - b.sortIndex).map(item => item.id);
  }, [environmentIds, envSampleMap]);

  const sampleLabelByEnvId = useMemo(
    () =>
      chain(environments)
        .keyBy(env => `${env.id}`)
        .mapValues(env => {
          if (!showSampleNumbers) {
            return env.name;
          } else {
            const sample =
              project.samplesByUID[
                Object.keys(project.samplesByUID).find(uid => project.samplesByUID[uid][0].environment.id === env.id) ||
                  ''
              ][0];
            const rawSortValue = getSampleSortValue(sample, project.id);
            const displayIndex = areSampleNumbersUnique
              ? sample.number
              : sequentialSampleIndexes[sample.id] || rawSortValue;
            return `${displayIndex} - ${env.name}`;
          }
        })
        .value(),
    [
      environments,
      showSampleNumbers,
      project.samplesByUID,
      project.id,
      sequentialSampleIndexes,
      areSampleNumbersUnique,
    ],
  );
  const envColorById = getEnvColorById(project);

  // Use sortedEnvironmentIds instead of environmentIds for palette
  const palette = sortedEnvironmentIds.map(id => envColorById[id]);
  const scale = scaleOrdinal<string, string>({
    domain: sortedEnvironmentIds,
    range: palette,
  });

  const selectedEnvironmentNames = filters.selectedEnvironmentNamesOrdered;
  const selectedEnvironmentIds = useMemo(
    () =>
      filters.selectedEnvironmentNamesOrdered
        .map(name => {
          const id = Object.keys(nameById).find(key => nameById[key] === name);
          return id ? String(id) : '';
        })
        .filter(Boolean),
    [filters.selectedEnvironmentNamesOrdered, nameById],
  );

  // Determine which environment IDs have data within the current time interval
  const environmentIdsWithDataInTimeRange = useMemo(() => {
    const result = new Set<string>();

    Object.values(project.samplesByUID).forEach(samples => {
      const sample = samples[0];
      if (sample.time && isWithinInterval(new Date(sample.time), filters.interval)) {
        result.add(String(sample.environment.id));
      }
    });

    return result;
  }, [project.samplesByUID, filters.interval]);

  const getOpacity = useCallback(
    (label: string) => {
      const OPACITY = 0.4;
      const correctLabel = showSampleNumbers ? label.split(' - ')[1] : label;
      const isBeingSelected = samplesBeingSelected.ids.includes(correctLabel);

      const isSelected =
        selectedEnvironmentNames.length === 0 ||
        selectedEnvironmentNames.includes(label) ||
        selectedEnvironmentIds.some(id => label === sampleLabelByEnvId[id]);

      // Check if this environment has any data in the current time range
      // Only apply this check if we have an actual time filter (not showing all time)
      const environmentId = Object.keys(sampleLabelByEnvId).find(id => sampleLabelByEnvId[id] === label);
      const hasDataInTimeRange = environmentId ? environmentIdsWithDataInTimeRange.has(environmentId) : true;

      // When using shift selection, previousSelection.current is the anchor point (first selection)
      if (activeKeys.current?.shift && (isSelected || isBeingSelected)) {
        return 1; // Show first value as selected
      }

      return (isSelected && isBeingSelected) || (!isSelected && !isBeingSelected) || !hasDataInTimeRange ? OPACITY : 1;
    },
    [
      showSampleNumbers,
      samplesBeingSelected.ids,
      selectedEnvironmentNames,
      selectedEnvironmentIds,
      sampleLabelByEnvId,
      environmentIdsWithDataInTimeRange,
    ],
  );

  const onClick = useCallback(
    (label: string, keys: KeysPressOptions) => {
      const selector = getSampleSelector(
        showSampleNumbers,
        selectedEnvironmentNames as L2Target[],
        environmentNames as L2Target[],
        samplesBeingSelected,
        setSamplesBeingSelected,
        toggleEnvironment,
        getSelectedEnvironmentIds(filters.selectedEnvironmentNamesOrdered, nameById),
        true,
        true,
        false,
        filters,
        queryFilters.setIntervalStable,
        project,
        nameById,
      );
      selector(label, keys);
      activeKeys.current = keys;
    },
    [
      filters,
      project,
      environmentNames,
      toggleEnvironment,
      nameById,
      samplesBeingSelected,
      setSamplesBeingSelected,
      selectedEnvironmentNames,
      showSampleNumbers,
    ],
  );
  const labelFormat = (label: string | number) =>
    !showSampleNumbers ? String(nameById[label]) : sampleLabelByEnvId[label];
  const getSamplesByEnvId = (label: string | number) => focusedSamplesByEnvId[Number(label)];
  const { description, valueTip } = getNormalisationDescription(normalisationMode);

  // Update focusedEnvironmentIds to only include environment IDs that have data in the time range
  const focusedEnvironmentIds = useMemo(() => {
    const baseIds =
      selectedEnvironmentIds.length > 0 ? selectedEnvironmentIds.map(Number) : sortedEnvironmentIds.map(Number);

    // Include all IDs, but client code will use the opacity function to gray out those without data
    return baseIds;
  }, [selectedEnvironmentIds, sortedEnvironmentIds]);

  // Get all unique statuses from focused samples to determine initial status
  const sampleStatus = useMemo(() => {
    const focusedSamples = Object.values(props.project.focusedByUID || {}).flat();
    const uniqueStatuses = [...new Set(focusedSamples.map(s => s.status))];
    return uniqueStatuses.length === 1 ? uniqueStatuses[0] : ' ';
  }, [props.project.focusedByUID]);

  return (
    <BaseLegend
      id={props.id}
      header="Samples"
      description={description}
      optionMinimum={2}
      TooltipContent={
        <UnorderedList>
          <ListItem>{valueTip}</ListItem>
          <ListItem>
            <Kbd>Click</Kbd> a label or chart bar to select a sample
          </ListItem>
          <ChartHotkeyInstructions label="samples" />
        </UnorderedList>
      }
      sampleStatuses={
        isAdmin && adminFeatures.isModifyAsAdminActive
          ? {
              update: adminFeatures.updateSampleStatuses,
              status: sampleStatus,
            }
          : undefined
      }
      canUpdateSampleStatuses={adminFeatures.isModifyAsAdminActive && isAdmin}
    >
      <Legend
        colorScale={scale}
        labelFormat={labelFormat}
        getOpacity={getOpacity}
        onClick={onClick}
        height={props.legendHeight}
        getSamplesByEnvId={adminFeatures.isModifyAsAdminActive ? getSamplesByEnvId : undefined}
        focusedEnvironmentIds={focusedEnvironmentIds}
        updateSampleStatuses={adminFeatures.updateSampleStatuses}
        filters={filters}
      />
    </BaseLegend>
  );
}

function getNormalisationDescription(mode: NormalisationMode): { description: string; valueTip: string } {
  switch (mode) {
    case NormalisationMode.MG_SS:
      return {
        description: 'Copies / mg of SS (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per mg of suspended solids',
      };
    case NormalisationMode.HOUR:
      return {
        description: 'Copies per hour (log)',
        valueTip: 'The graph presents Log₁₀ of copies per hour (flow normalised)',
      };
    case NormalisationMode.LITRE:
      return {
        description: 'Copies per litre (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per litre',
      };
    case NormalisationMode.MG_BOD:
      return {
        description: 'Copies / mg BOD (log)',
        valueTip: 'The graph presents Log₁₀ of gene copies per mg of biochemical oxygen demand',
      };
    case NormalisationMode.TEN_UL_DILUTED_DNA:
      return {
        description: 'Analysed copies (log)',
        valueTip:
          'The graph presents Log₁₀ of the numbers of gene copies in 10 uL of diluted DNA analysed on the SmartChip qPCR run.',
      };
    case NormalisationMode.SIXTEEN_S:
      return {
        description: 'Relative abundance (log)',
        valueTip: 'The graph presents Log₁₀ of abundance, relative to 16S rRNA genes',
      };
  }
}
