import { Box, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useWindowSize } from '@react-hook/window-size';
import { Footer } from '@resistapp/client/components/page-layout/footer/footer';
import { headerHeight } from '@resistapp/client/components/page-layout/header-bar/header-bar';
import { binColor } from '@resistapp/client/components/plots/legends/heatmap-bins';
import { ProjectSelector } from '@resistapp/client/components/project-selector/project-selector';
import { oldTheme } from '@resistapp/client/components/shared/old-styles';
import { theme } from '@resistapp/client/components/shared/theme';
import { useUser } from '@resistapp/client/contexts/use-user-context';
import { Filters } from '@resistapp/client/data-utils/filter-data/filter';
import { useWorldmap } from '@resistapp/client/hooks/api';
import { useNavigateWithQuery } from '@resistapp/client/hooks/use-navigate-with-query';
import { useQueryFilters } from '@resistapp/client/hooks/use-query-filters/use-query-filters';
import { AbundanceStats } from '@resistapp/common/api-types';
import { EnvironmentType, friendlyEnvironmentType } from '@resistapp/common/environment-types';
import { isAdmin } from '@resistapp/common/features';
import { NormalisationMode } from '@resistapp/common/types';
import { Graticule, Mercator } from '@visx/geo';
import useTooltipInPortal, { UseTooltipInPortal } from '@visx/tooltip/lib/hooks/useTooltipInPortal';
import { AnimatePresence, motion } from 'framer-motion';
import { compact, Dictionary } from 'lodash';
import { useEffect, useState } from 'react';
import { feature } from 'topojson-client';
import { Topology } from 'topojson-specification';
import topology from './world-topo.json';

const startAnimationLength = 0.5;

export type GeoMercatorProps = {
  width: number;
  height: number;
  events?: boolean;
};

interface FeatureShape {
  type: 'Feature';
  id: string;
  geometry: { coordinates: Array<Array<[number, number]>>; type: 'Polygon' };
  properties: { name: string };
}

const typedTopology = topology as unknown as Topology;
const world = feature(typedTopology, typedTopology.objects.units);

function AnimatedTitle() {
  const [currentTypeIndex, setCurrentTypeIndex] = useState(0);
  const environmentTypes = [
    EnvironmentType.WASTEWATER,
    EnvironmentType.SURFACE_WATER,
    EnvironmentType.SEDIMENT,
    EnvironmentType.SLUDGE,
    EnvironmentType.SOIL,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTypeIndex(prev => (prev + 1) % environmentTypes.length);
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, [environmentTypes.length]);

  return (
    <Title $starting={false}>
      Antibiotic Resistance
      <br />
      <AnimatePresence mode="wait">
        <motion.span
          key={currentTypeIndex}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          in {friendlyEnvironmentType(environmentTypes[currentTypeIndex])}
        </motion.span>
      </AnimatePresence>
    </Title>
  );
}

export function WorldView() {
  const { data } = useWorldmap();
  const navigate = useNavigateWithQuery();
  const [width] = useWindowSize();
  const { user } = useUser();
  const { queryFilters } = useQueryFilters(undefined);
  const { focusInfo, filters } = queryFilters;
  const tooltipStuff = useTooltipInPortal({
    scroll: false,
    detectBounds: false,
  });

  const tooltipRef = tooltipStuff.containerRef;

  const groupingData = data && data[filters.selectedTargetGrouping];
  const centerX = 0.5 * width;
  const centerY = 0.33 * width;
  const height = width - 0.17 * width; // centerY clips this much in the north
  const scale = (width / 630) * 100;

  const legendFitsOnMap = legendHeight < height - headerHeight - 40;
  const typedWorld = world as unknown as { features: FeatureShape[] };

  return (
    <>
      {width < 10 ? null : (
        <svg
          id="worldview-map"
          ref={tooltipRef}
          width={width}
          height={height}
          style={{
            backgroundColor: theme.colors.neutral50,
            position: 'absolute',
            top: '0px',
            left: '0px',
            paddingTop: '40px',
          }}
        >
          <Mercator<FeatureShape> data={typedWorld.features} scale={scale} translate={[centerX, centerY]}>
            {mercator => {
              // Draw Finland last
              const finland = mercator.features.find(
                ({ feature: localFeature }) => localFeature.properties.name === 'Finland',
              );
              const others = mercator.features.filter(
                ({ feature: localFeature }) => localFeature.properties.name !== 'Finland',
              );
              const sortedFeatures = compact([...others, finland]);

              return (
                <g>
                  <Graticule graticule={g => mercator.path(g) || ''} stroke={oldTheme.darkGray} strokeOpacity={0.0} />
                  {sortedFeatures.map(({ feature: localFeature, path }, i) => (
                    <Country
                      key={`map-feature-${i}`}
                      tooltipStuff={tooltipStuff}
                      filters={filters}
                      started={false}
                      groupingData={groupingData}
                      focusInfo={focusInfo}
                      feature={localFeature}
                      path={path}
                      isDemo={localFeature.properties.name === 'Finland'}
                    />
                  ))}
                </g>
              );
            }}
          </Mercator>
        </svg>
      )}

      <LandingTitle
        style={{ top: 0 }}
        onClick={() => {
          if (user === null) {
            navigate('/login', false);
          }
        }}
      >
        <AnimatedTitle />
        <Instructions $starting={false}>
          {!user ? (
            <LoginHint
              onClick={() => {
                navigate('/login', false);
              }}
            >
              Click to login
            </LoginHint>
          ) : (
            <Flex justifyContent="center" width="100%">
              <Box width={{ base: '90%', sm: '70%', md: '50%', lg: '33%' }}>
                <ProjectSelector showStatus={isAdmin(user)} menuHeight="640px" />
              </Box>
            </Flex>
          )}
        </Instructions>
      </LandingTitle>

      {legendFitsOnMap && <Footer top={height} />}
    </>
  );
}

interface CountryProps {
  started: boolean;
  filters: Filters;
  path: string | null;
  focusInfo: string;
  feature: FeatureShape;
  groupingData: Dictionary<Dictionary<AbundanceStats>> | undefined;
  tooltipStuff: UseTooltipInPortal;
  isDemo: boolean;
}

function Country(props: CountryProps) {
  const { path, started, filters, groupingData, feature: _feature, isDemo } = props;

  const key = filters.selectedTargets.length === 1 ? filters.selectedTargets[0] : 'all';
  const stats = groupingData?.[_feature.id]?.[key];
  const color = started ? binColor(stats?.median, NormalisationMode.SIXTEEN_S, true) : 'white';

  const stroke = started ? (isDemo ? oldTheme.blue : oldTheme.slightBlue) : oldTheme.faintGreen;

  return (
    <>
      <path d={path || ''} fill={color} stroke={stroke} strokeWidth={started && isDemo ? 1 : 0.5} />
    </>
  );
}

const legendHeight = 530;
const LandingTitle = styled.div`
  padding-top: ${oldTheme.spacing.m};
  padding-right: ${oldTheme.spacing.xs};
  background-color: ${oldTheme.transparent};
  position: absolute;
  width: 100%;
  height: 100%;
`;

interface TestingProps {
  $starting: boolean;
}

const Title = styled.div<TestingProps>`
  color: ${props => (props.$starting ? oldTheme.transparent : oldTheme.blue)};
  text-align: center;
  padding-top: 180px;
  font-family: ${oldTheme.fontFamily};
  font-size: 60px;
  line-height: 67px;
  font-weight: 700;
  transition: color ${startAnimationLength}s ease-in-out;
`;

const Instructions = styled.div<TestingProps>`
  color: ${props => (props.$starting ? oldTheme.transparent : oldTheme.blue)};
  text-align: center;
  font-family: ${oldTheme.fontFamily};
  font-size: 32px;
  margin-top: 32px;
  line-height: 32px;
  font-weight: 700px;
  transition: color ${startAnimationLength}s ease-in-out;
`;

const LoginHint = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
