import { handleFiltersSelectionWithKeys } from '@resistapp/client/data-utils/filter-data/filter';
import { L2Target } from '@resistapp/common/assays';
import { EnvironmentTypeGroup, filterGroupEnvironments } from '@resistapp/common/comparable-env-groups';
import { DEFAULT_END_INTERVAL, DEFAULT_START_INTERVAL, type StandardDateFormat } from '@resistapp/common/friendly';
import { FullProject, FullSamplesByUID } from '@resistapp/common/types';
import { sortUniqEnvironments } from '@resistapp/common/utils';
import { ascending } from 'd3-array';
import { ScaleOrdinal } from 'd3-scale';
import { differenceInSeconds } from 'date-fns';
import { difference, keyBy, mapValues, uniqBy } from 'lodash';
import { KeysPressOptions } from '../legends/legend';

export const chartLayoutValues = {
  plotLeftOffset: 71, // For the y-axis ticks and markers
  xAxisBottomMargin: 12,
  xAxisLeftMargin: 1,
  yAxisLeftMargin: 1,
  heatmapYAxisTopMargin: 10,
  heatMapRightMargin: 5,
  heatmapYAxisTopMargin2: 15,
  yAxisMarginLeft: 6,
  // TODO: I think with proper design there should be no need for this extra margin.
  // Now the bar and box plots work with above "yAxisMarginLeft", but heatmap needs this 6
  heatmapYAxisMarginLeftExtraMargin: 6,
  xAxisHeight: 40,
  xAxisTopMargin: 5,
};

export function getShortestStepInSeconds(times: Date[]) {
  const sortedTimes = (() => {
    const uniqTimes = uniqBy(times, time => time.toISOString());
    uniqTimes.sort(ascending);
    return uniqTimes;
  })();
  return sortedTimes.reduce<number>((minSoFar, current, i) => {
    if (i === 0) {
      return minSoFar;
    }
    const previous = sortedTimes[i - 1];
    return Math.min(minSoFar, differenceInSeconds(current, previous));
  }, Number.MAX_SAFE_INTEGER);
}

export function exponentToSuperScript(exponent: number) {
  switch (exponent) {
    case 0:
      return '⁰';
    case -1:
      return '⁻¹';
    case -2:
      return '⁻²';
    case -3:
      return '⁻³';
    case -4:
      return '⁻⁴';
    case -5:
      return '⁻⁵';
    case -6:
      return '⁻⁶';
    case -7:
      return '⁻⁷';
    case -8:
      return '⁻⁸';
    case -9:
      return '⁻⁹';
    default:
      throw Error(`Unsupported exponent: ${exponent}`);
  }
}

export function getHeatmapChartOneGeneHeight(numKeys: number) {
  return numKeys > 100 ? 5 : 10;
}

// We need to unregister the previous event listener before registering a new one, to avoid bugs and memory leaks
let activehandleKeyUpEventListener: (e: KeyboardEvent) => void = () => {};
// We track what label was previously selected, for using the shift key functionality when selecting samples.
let previouslySelectedLabel: string | undefined = undefined;
export function getSampleSelector(
  showSampleNumbers: boolean,
  selectedEnvironmentNames: L2Target[],
  allEnvironmentNames: L2Target[],
  samplesBeingSelected: { ids: string[]; removeOldSelections: boolean },
  setSamplesBeingSelected: (value: { ids: string[]; removeOldSelections: boolean }) => void,
  toggleEnvironment: (envName: string | string[], only: boolean) => void,
  selectedEnvironmentIds: string[],
  commitMode: boolean,
  isLegend: boolean,
  disableShiftSelection = false,
  filters?: { interval: { start: Date; end: Date } },
  setIntervalStable?: (start: Date | StandardDateFormat, end: Date | StandardDateFormat) => void,
  project?: FullProject,
  nameById?: Record<string, string>,
) {
  return (label: string, keys: KeysPressOptions) => {
    if (disableShiftSelection) {
      return;
    }
    const newSelectedLabel = !showSampleNumbers && !isLegend ? label : label.replace(/^\d+\s-\s(.+)/, '$1');
    const selectedEnvironmentNamesOrIds =
      showSampleNumbers && !isLegend ? selectedEnvironmentIds : selectedEnvironmentNames;
    const previousLabel = keys.shift
      ? previouslySelectedLabel ||
        (selectedEnvironmentNamesOrIds.length === 1
          ? selectedEnvironmentNamesOrIds[0]
          : samplesBeingSelected.ids[samplesBeingSelected.ids.length - 1])
      : samplesBeingSelected.ids[samplesBeingSelected.ids.length - 1];
    const [newSelections, removeOldSelections] = handleFiltersSelectionWithKeys(
      commitMode ? samplesBeingSelected.ids : selectedEnvironmentNamesOrIds,
      allEnvironmentNames,
      newSelectedLabel,
      previousLabel,
      keys,
    );

    window.removeEventListener('keyup', activehandleKeyUpEventListener);
    if (commitMode && (keys.ctrl || keys.shift)) {
      const newSelectionsArray = Array.isArray(newSelections) ? newSelections : [newSelections];
      const existingOldSelections = keys.shift ? [] : difference(samplesBeingSelected.ids, newSelectionsArray);
      const correctedSamplesBeingSelected = difference(newSelectionsArray, samplesBeingSelected.ids);

      // Include the first selection (previousLabel) in shift selections if it exists
      const shiftSelections =
        keys.shift && previousLabel
          ? newSelectionsArray
          : difference(newSelectionsArray, selectedEnvironmentNamesOrIds);

      const correctlySelected = {
        ids: [...correctedSamplesBeingSelected, ...existingOldSelections],
        removeOldSelections,
      };
      setSamplesBeingSelected(keys.shift ? { ids: shiftSelections, removeOldSelections } : correctlySelected);

      // Track which keys were held down when click occurred
      const expectedKeys = {
        shift: keys.shift,
        ctrl: keys.ctrl,
      };

      const handleKeyUp = (e: KeyboardEvent) => {
        // Only trigger the selection if one of the tracked keys is released
        if (
          (expectedKeys.shift && e.key === 'Shift') ||
          (expectedKeys.ctrl && (e.key === 'Control' || e.key === 'Meta'))
        ) {
          // Check if any of the selected environments have data in the time range
          const hasDataInTimeRange = newSelectionsArray.every(envName => {
            if (!nameById || !project) return false;

            const envId = Object.keys(nameById).find(key => nameById[key] === envName);
            if (!envId) return false;

            const samples =
              project.samplesByUID[
                Object.keys(project.samplesByUID).find(
                  uid => project.samplesByUID[uid][0].environment.id === Number(envId),
                ) || ''
              ];

            return samples.some(sample => {
              if (!sample.time || !filters?.interval) return false;
              const sampleDate = new Date(sample.time);
              return sampleDate >= filters.interval.start && sampleDate <= filters.interval.end;
            });
          });

          if (!hasDataInTimeRange && setIntervalStable) {
            setIntervalStable(DEFAULT_START_INTERVAL, DEFAULT_END_INTERVAL);
          }

          toggleEnvironment(keys.shift ? shiftSelections : correctlySelected.ids, removeOldSelections);
          setSamplesBeingSelected({ ids: [], removeOldSelections: true });

          // Update the previously selected label for shift selection
          if (!keys.ctrl) {
            previouslySelectedLabel = newSelectedLabel;
          }

          // Remove the event listener after handling the specific key release
          window.removeEventListener('keyup', activehandleKeyUpEventListener);
        }
      };

      window.addEventListener('keyup', handleKeyUp);
      activehandleKeyUpEventListener = handleKeyUp;

      // Only update previouslySelectedLabel when using ctrl, not shift
      // This ensures shift selections maintain the correct reference point
      if (keys.ctrl) {
        previouslySelectedLabel = newSelectedLabel;
      }

      // This is returned for tests
      return handleKeyUp;
    }

    previouslySelectedLabel = newSelectedLabel;
    toggleEnvironment(newSelections, removeOldSelections);
    // Not using commit mode, then we have to empty the samplesBeingSelected state to avoid bugs and unexpected states
    setSamplesBeingSelected({ ids: [], removeOldSelections: true });
    return;
  };
}

export function getSelectedEnvironmentIds(
  selectedEnvironmentNames: string[],
  environmentNames: {
    [x: string]: string;
  },
) {
  return selectedEnvironmentNames.map(name => {
    const id = Object.keys(environmentNames).find(key => environmentNames[key] === name);
    return id ? String(id) : '';
  });
}

export function getEnvironmentDatas(
  samplesByUID: FullSamplesByUID,
  selectedEnvTypeGroup: EnvironmentTypeGroup,
  sortByProject?: number | undefined,
) {
  const environments = sortUniqEnvironments(samplesByUID, sortByProject);
  const nameById = mapValues(
    keyBy(environments, env => `${env.id}`),
    env => env.name,
  );
  const environmentIds = filterGroupEnvironments(environments, selectedEnvTypeGroup).map(env => `${env.id}`);
  const environmentNames = environmentIds.map(id => nameById[id]);

  return { environments, nameById, environmentIds, environmentNames };
}

export function getSemiTransparentColorFromScale(scale: ScaleOrdinal<string, string>) {
  return (d: string) => {
    const hex = scale(d);
    return getSemiTransparentColor(hex);
  };
}
export function getSemiTransparentColor(hex: string) {
  return hex + '80'; // 80 in hex = 50% opacity
}
